export const taxRegimes = Object.freeze({
  mei: 'MEI',
  simplesNacional: 'Simples Nacional',
  simplesNacionalReceitaBruta: 'Simples Nacional, excesso sublime da receita bruta',
  lucroPresumido: 'Lucro Presumido',
  lucroReal: 'Lucro Real',
  isento: 'Isento',
});

export function getName(value) {
  return taxRegimes[value];
}
