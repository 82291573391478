export const PENDING = 'PENDING';
export const RECEIVED = 'RECEIVED';
export const CONFIRMED = 'CONFIRMED';
export const OVERDUE = 'OVERDUE';
export const REFUNDED = 'REFUNDED';
export const RECEIVED_IN_CASH = 'RECEIVED_IN_CASH';
export const REFUND_REQUESTED = 'REFUND_REQUESTED';
export const REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS';

const statuses = {
  [PENDING]: 'Aguardando',
  [RECEIVED]: 'Recebida',
  [CONFIRMED]: 'Confirmada',
  [OVERDUE]: 'Vencida',
  [REFUNDED]: 'Estornada',
  [RECEIVED_IN_CASH]: 'Recebida em dinheiro',
  [REFUND_REQUESTED]: 'Estorno solicitado',
  [REFUND_IN_PROGRESS]: 'Estorno em progresso',
};

export function getName(value) {
  return statuses[value];
}
