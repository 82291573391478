export const specialTaxRegimes = Object.freeze({
  automatico: 'Automático',
  nenhum: 'Nenhum',
  microempresaMunicipal: 'Microempresa Municipal',
  estimativa: 'Estimativa',
  sociedadeDeProfissionais: 'Sociedade de Profissionais',
  cooperativa: 'Cooperativa',
  microempreendedorIndividual: 'Micro-empreendedor Individual',
  microempresarioEmpresaPequenoPorte: 'Micro-empresario Empresa Pequeno Porte',
});

export function getName(value) {
  return specialTaxRegimes[value];
}
