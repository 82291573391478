<template>
  <div class="page-container customer-page">
    <portal to="page-name">Conta do cliente</portal>

    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Conta do cliente</h1>
      </div>
      <div class="card-body">
        <div class="loading loading-lg" v-if="loading"></div>
        <template v-else>
          <st-card-details title="Dados gerais">
            <div class="columns">
              <st-card-detail-item class="column col-3" title="Tipo de cadastro">
                {{ isPerson ? 'Pessoa Física' : 'Pessoa Jurídica' }}
              </st-card-detail-item>
              <st-card-detail-item class="column col-4" :title="data.identity.type.toUpperCase()">
                {{ data.identity.value | cnpj }}
              </st-card-detail-item>
              <st-card-detail-item class="column col-5" title="Nome">
                {{ data.name }}
              </st-card-detail-item>
              <st-card-detail-item class="column col-3" title="Telefone">
                <template v-if="data.phone">{{ data.phone | phone }}</template>
                <template v-else>--</template>
              </st-card-detail-item>
              <st-card-detail-item class="column col-4" title="Celular">
                <template v-if="data.cellphone">{{ data.cellphone | phone }}</template>
                <template v-else>--</template>
              </st-card-detail-item>
              <st-card-detail-item class="column col-5" title="Email">
                <template v-if="data.email">{{ data.email }}</template>
                <template v-else>--</template>
              </st-card-detail-item>
              <st-card-detail-item class="column col-12" title="Endereço">
                <template v-if="data.address">{{ data.address | address('full') }}</template>
                <template v-else>--</template>
              </st-card-detail-item>
            </div>
          </st-card-details>

          <st-card-details title="Responsável">
            <div class="columns">
              <st-card-detail-item class="column col-3" title="CPF">
                {{ data.owner.identity | cpf }}
              </st-card-detail-item>
              <st-card-detail-item class="column col-4" title="Nome">
                {{ data.owner.name }}
              </st-card-detail-item>
              <st-card-detail-item class="column col-5" title="Contato">
                <span v-if="data.owner.cellphone">{{ data.owner.cellphone | phone }}</span>
                <span
                  v-if="data.owner.cellphone && data.owner.email"
                  class="mr-2 ml-2 text-gray"
                >|</span>
                <span v-if="data.owner.email">{{ data.owner.email }}</span>
              </st-card-detail-item>
            </div>
          </st-card-details>

          <st-card-details title="Usuário principal">
            <div class="columns">
              <st-card-detail-item class="column col-3" title="CPF">
                {{ data.user.identity | cpf }}
              </st-card-detail-item>
              <st-card-detail-item class="column col-4" title="Nome">
                {{ data.user.name }}
              </st-card-detail-item>
              <st-card-detail-item class="column col-5" title="Contato">
                {{ data.user.email }}
              </st-card-detail-item>
            </div>
          </st-card-details>

          <st-card-details title="Cobrança">
            <div class="columns">
              <table class="table">
                <thead>
                <tr>
                  <th>Dia</th>
                  <th>Valor</th>
                  <th>Email</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in data.billings" :key="i">
                  <td>{{ item.day }}</td>
                  <td>{{ item.value | currency }}</td>
                  <td>{{ item.email }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </st-card-details>
        </template>

        <st-card-details title="Backup">
          <p class="mb-2">
            A solicitação do backup deve ser feita pelo e-mail
            <a href="mailto:stenci@stenci.com.br">stenci@stenci.com.br</a>,
            informando os dados da conta, como CPF ou CNPJ.
          </p>
          <p>O prazo para envio do backup é de até 7 dias.</p>
          <button
            class="btn btn-gray-outline badge"
            data-badge="em breve"
          >Solicitar backup</button>
        </st-card-details>

        <st-card-details title="Cancelamento">
          <p class="mb-2">
            A solicitação de cancelamento da conta deve ser feita pelo e-mail
            <a href="mailto:stenci@stenci.com.br">stenci@stenci.com.br</a>,
            com os dados da conta, como CPF ou CNPJ.
          </p>
          <p>
            O prazo para o cancelamento final é de 30 dias após a solicitação,
            e haverá uma última cobrança pro-rata referente aos dias utilizados no período.
          </p>
        </st-card-details>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      data: null,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    isPerson() {
      return this.data && this.data.identity.type === 'cpf';
    },
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const { data } = await this.$http.get('/customers/me');
        this.data = data;
      } catch (e) {
        this.$toast.error(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.customer-page {
  .card-details {
    margin-bottom: $layout-spacing-lg;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
