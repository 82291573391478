<template>
  <div class="page-container settings-page">
    <div class="card card-page" v-if="hasAccess">
      <div class="card-body">
        <st-tabs>
          <st-tab id="general" name="Parâmetros gerais">
            <general-tab></general-tab>
          </st-tab>
          <st-tab id="communication" name="Comunicação" :active="true">
            <communication-tab
              :loading="loading"
              :settings="settings"
            ></communication-tab>
          </st-tab>
          <st-tab id="integration" name="Integração">
            <integration-tab
              :loading="loading"
              :settings="settings"
            ></integration-tab>
          </st-tab>
        </st-tabs>
      </div>
    </div>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import { MANAGE_APPOINTMENT_SETTINGS } from '@/data/actions/modules/clinical';
import Forbidden from '@/components/auth/Forbidden.vue';
import GeneralTab from './tabs/General.vue';
import CommunicationTab from './tabs/Communication.vue';
import IntegrationTab from './tabs/Integration.vue';

export default {
  data() {
    return {
      settings: null,
      loading: true,
    };
  },
  components: {
    Forbidden,
    GeneralTab,
    CommunicationTab,
    IntegrationTab,
  },
  mounted() {
    this.load();
  },
  computed: {
    hasAccess() {
      return this.$can(MANAGE_APPOINTMENT_SETTINGS);
    },
  },
  methods: {
    async load() {
      if (!this.hasAccess) return;

      this.loading = true;
      try {
        const { data } = await this.$http.get('/facility-settings');
        this.settings = data;
      } catch (e) {
        this.$toast.error(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.settings-page {
  max-width: $size-lg;
  .tab-panel {
    padding-top: $layout-spacing;
    .setting-item:first-child {
      border-top: 0;
    }
  }
}
</style>
