<template>
  <div class="loading loading-lg mt-2" v-if="loading"></div>
  <table class="table" v-else>
    <tbody>
    <general-item
      v-for="item in settings"
      :item="item"
      :key="item.key"
    ></general-item>
    </tbody>
  </table>
</template>

<script>
import GeneralItem from './GeneralItem.vue';

export default {
  data() {
    return {
      loading: false,
      settings: [],
    };
  },
  components: {
    GeneralItem,
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const { data } = await this.$http.get('/customer-settings');
        this.settings = data;
      } catch (e) {
        this.$toast.error(e);
      }
      this.loading = false;
    },
  },
};
</script>
