<template>
  <div class="page-container account-payments-page">
    <portal to="page-name">Pagamentos</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Pagamentos</h1>
      </div>

      <div class="card-body">
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Pagamentos</p>
              <p class="empty-subtitle">
                Nenhum pagamento encontrado.
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th class="hide-sm" style="width:30px">#</th>
                <th>Data</th>
                <th>Valor</th>
                <th>Descrição</th>
                <th>Status</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in data.items" :key="i">
                <td class="hide-sm">{{ i + 1 }}</td>
                <td>{{ item.dueDate | date }}</td>
                <td>{{ item.value | currency }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <span
                    class="label label-rounded"
                    :class="item.statusLabelClass"
                  >{{ item.statusName }}</span>
                </td>
                <td class="text-right">
                  <a
                    :href="item.invoiceUrl"
                    target="_blank"
                    class="btn btn-sm btn-action btn-icon btn-secondary tooltip tooltip-left"
                    data-tooltip="Visualizar fatura"
                  ><fa-icon :icon="['fal', 'file-invoice-dollar']" /></a>
                </td>
              </tr>
              </tbody>
            </table>
<!--            <pre>{{ data.items }}</pre>-->
          </template>
        </template>
      </div>

      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as asaasStatuses from '@/data/asaas-statuses';

export default {
  data() {
    return {
      loading: false,
      loadingMore: false,
      data: {
        items: [],
        hasMore: false,
        limit: 12,
        offset: 0,
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      this.load();
    },
    async load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      try {
        const { data } = await this.$http.get('/customer-payments', { params });

        data.items = data.items.map((item) => {
          item.statusName = asaasStatuses.getName(item.status);
          item.statusLabelClass = this.statusLabelClass(item.status);
          return item;
        });

        this.data.hasMore = data.hasMore;
        this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
      } catch (e) {
        this.$toast.error(e);
      }

      this.loading = false;
      this.loadingMore = false;
    },
    statusLabelClass(status) {
      switch (status) {
        case asaasStatuses.RECEIVED: return 'label-success';
        case asaasStatuses.CONFIRMED: return 'label-info';
        case asaasStatuses.OVERDUE: return 'label-error';
        case asaasStatuses.PENDING: return 'label-warning';
        default: return '';
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.account-payments-page {
}
</style>
