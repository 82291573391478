<template>
  <tr>
    <td>{{ label }}</td>
    <td>
      <div class="form-group">
        <label class="form-switch" v-if="item.type === 'boolean'">
          <input type="checkbox" v-model="value">
          <i class="form-icon"></i>
        </label>
        <dx-input-number
          type="text" class="form-input" v-model="value"
          v-else-if="item.type === 'number'" />
        <input type="text" class="form-input" v-model="value" v-else>
      </div>
    </td>
  </tr>
</template>

<script>
import { capitalize } from '@/filters/capitalize';
import settings from '@/data/settings';

export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      value: this.item.value,
      saving: false,
    };
  },
  watch: {
    value() {
      this.update();
    },
  },
  methods: {
    update() {
      this.saving = true;
      this.$http
        .put('/customer-settings', {
          key: this.item.key,
          value: this.value,
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
  },
  computed: {
    label() {
      return this.item.key in settings
        ? settings[this.item.key]
        : (
          this.item.key.split('.')
            .map(v => v.replace(/[A-Z]/g, $ => ` ${$.toUpperCase()}`))
            .join(' ')
            .trim()
            .split(' ')
            .map(v => capitalize(v))
            .join(' ')
        );
    },
  },
};
</script>
