<template>
  <div class="page-container">
    <portal to="page-name">Configurações</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Configurações</h1>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
          <tr>
            <th>Parâmetros</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <setting-row v-for="item in settings" :item="item" :key="item.key" />
          </tbody>
        </table>
      </div>
    </div>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import { MANAGE_APPOINTMENT_SETTINGS } from '@/data/actions/modules/clinical';
import Forbidden from '@/components/auth/Forbidden.vue';
import SettingRow from './SettingRow.vue';

export default {
  data() {
    return {
      settings: [],
    };
  },
  components: {
    SettingRow,
    Forbidden,
  },
  computed: {
    hasAccess() {
      return this.$can(MANAGE_APPOINTMENT_SETTINGS);
    },
  },
  mounted() {
    if (this.hasAccess) {
      this.$http.get('/customer-settings')
        .then(({ data }) => {
          this.settings = data
            .filter(({ key }) => !key.startsWith('relationship.'));
        })
        .catch(() => {});
    }
  },
};
</script>
