<template>
  <dx-modal
    :value="true"
    title="Nota Fiscal Eletrônica"
    @input="close"
    size="md"
    id="integration-invoice-modal"
  >
    <st-tabs>
      <st-tab name="Informações da empresa">
        <div class="columns pb-2">
          <div
            class="column col-4 form-group"
            :class="{'has-error': $v.form.federalTaxNumber.$error}"
          >
            <label for="federalTaxNumber" class="form-label">* CNPJ</label>
            <input
              class="form-input"
              id="federalTaxNumber"
              type="text"
              v-model="form.federalTaxNumber"
              placeholder="00.000.000/0000-00"
              v-mask-cnpj
            >
            <template v-if="$v.form.federalTaxNumber.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.federalTaxNumber.required"
              >Campo obrigatório</div>
              <div
                class="form-input-hint"
                v-else-if="!$v.form.federalTaxNumber.cnpj"
              >CNPJ inválido</div>
            </template>
          </div>
          <div
            class="column col-8 form-group"
            :class="{'has-error': $v.form.name.$error}"
          >
            <label for="name" class="form-label">* Razão social</label>
            <input
              class="form-input"
              id="name"
              type="text"
              v-model="form.name"
              placeholder="Razão social"
            >
            <template v-if="$v.form.name.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.name.required"
              >Campo obrigatório</div>
            </template>
          </div>
          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.email.$error}"
          >
            <label for="email" class="form-label">* Email</label>
            <input
              class="form-input"
              id="email"
              type="text"
              v-model="form.email"
              placeholder="email@email.com"
            >
            <template v-if="$v.form.email.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.email.required"
              >Campo obrigatório</div>
              <div
                class="form-input-hint"
                v-else-if="!$v.form.email.email"
              >Email inválido</div>
            </template>
          </div>
          <div class="column col-6 form-group">
            <label for="tradeName" class="form-label">Nome fantasia</label>
            <input
              class="form-input"
              id="tradeName"
              type="text"
              v-model="form.tradeName"
              placeholder="Nome fantasia"
            >
          </div>
        </div>

        <div class="divider text-center" data-content="Endereço"></div>

        <div class="columns">
          <div
            class="column col-4 form-group"
            :class="{'has-error': $v.form.address.postalCode.$error}"
          >
            <label for="address-postalCode" class="form-label">CEP</label>
            <input
              class="form-input"
              id="address-postalCode"
              type="text"
              v-model="form.address.postalCode"
              placeholder="00.000-000"
              v-mask-cep
            >
            <template v-if="$v.form.address.postalCode.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.address.postalCode.required"
              >Campo obrigatório</div>
              <div
                class="form-input-hint"
                v-else-if="!$v.form.address.postalCode.cep"
              >CEP inválido</div>
            </template>
          </div>
          <div class="column"></div>
          <div
            class="column col-8 form-group"
            :class="{'has-error': $v.form.address.street.$error}"
          >
            <label for="address-street" class="form-label">* Endereço</label>
            <input
              class="form-input"
              id="address-street"
              type="text"
              v-model="form.address.street"
              placeholder="Avenida Brasil"
            >
            <template v-if="$v.form.address.street.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.address.street.required"
              >Campo obrigatório</div>
            </template>
          </div>
          <div
            class="column col-4 form-group"
            :class="{'has-error': $v.form.address.number.$error}"
          >
            <label for="address-number" class="form-label">* Número</label>
            <input
              class="form-input"
              id="address-number"
              type="text"
              v-model="form.address.number"
              placeholder="0"
            >
            <template v-if="$v.form.address.number.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.address.number.required"
              >Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 form-group">
            <label for="address-info" class="form-label">Complemento</label>
            <input
              class="form-input"
              id="address-info"
              type="text"
              v-model="form.address.additionalInformation"
              placeholder="Complemento"
            >
          </div>
          <div class="column col-6 form-group">
            <label for="address-district" class="form-label">Bairro</label>
            <input
              class="form-input"
              id="address-district"
              type="text"
              v-model="form.address.district"
              placeholder="Bairro"
            >
          </div>
          <div
            class="column col-8 form-group"
            :class="{'has-error': $v.form.address.city.$error}"
          >
            <label for="address-city" class="form-label">* Cidade</label>
            <div class="input-group">
              <input
                class="form-input"
                id="address-city"
                type="text"
                v-model="form.address.city.name"
                placeholder="Cidade"
              >
              <input
                class="form-input"
                id="address-city-code"
                type="text"
                v-model="form.address.city.code"
                placeholder="Código IBGE"
                style="flex: 0 0 40%"
              >
            </div>
            <template v-if="$v.form.address.city.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.address.city.required"
              >Campo obrigatório</div>
            </template>
          </div>
          <div
            class="column col-4 form-group"
            :class="{'has-error': $v.form.address.state.$error}"
          >
            <label for="address-state" class="form-label">* Estado</label>
            <select id="address-state" name="address-state"
                    class="form-select" v-model="form.address.state">
              <option value="">[Selecione]</option>
              <option v-for="(text, value) in states"
                      :value="value" :key="value">{{ text }}</option>
            </select>
            <template v-if="$v.form.address.state.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.address.state.required"
              >Campo obrigatório</div>
            </template>
          </div>
        </div>
      </st-tab>

      <st-tab name="Informações fiscais">
        <div class="columns pb-2">
          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.municipalTaxNumber.$error}"
          >
            <label
              for="municipalTaxNumber"
              class="form-label"
            >* Inscrição Municipal (CCM)</label>
            <input
              class="form-input"
              id="municipalTaxNumber"
              type="text"
              v-model="form.municipalTaxNumber"
              placeholder=""
            >
            <template v-if="$v.form.municipalTaxNumber.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.municipalTaxNumber.required"
              >Campo obrigatório</div>
            </template>
          </div>
          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.taxRegime.$error}"
          >
            <label for="taxRegime" class="form-label">* Tipo do Regime Tributário</label>
            <select id="taxRegime" class="form-select" v-model="form.taxRegime">
              <option value="">[selecione]</option>
              <option
                v-for="(text, value) in taxRegimes"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
            <template v-if="$v.form.taxRegime.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.taxRegime.required"
              >Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 form-group">
            <label
              for="specialTaxRegime"
              class="form-label"
            >Tipo do regime especial de tributação</label>
            <select id="specialTaxRegime" class="form-select" v-model="form.specialTaxRegime">
              <option value="">[selecione]</option>
              <option
                v-for="(text, value) in specialTaxRegimes"
                :value="value"
                :key="value"
              >{{ text }}</option>
            </select>
          </div>
          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.legalNature.$error}"
          >
            <label for="legalNature" class="form-label">* Código da Natureza Jurídica</label>
            <select id="taxRegime" class="form-select" v-model="form.legalNature">
              <option value="">[selecione]</option>
              <option
                v-for="(text, value) in legalNatures"
                :value="value"
                :key="value"
              >{{ value }} - {{ text }}</option>
            </select>
            <template v-if="$v.form.legalNature.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.legalNature.required"
              >Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 form-group">
            <label for="iss-rate" class="form-label">Alíquota ISS</label>
            <div class="input-group">
              <span class="input-group-addon">%</span>
              <dx-input-number
                class="form-input"
                id="iss-rate"
                v-model="form.issRate"
                maxlength="5"
                :precision="2"
              />
            </div>
          </div>
        </div>

        <div class="divider text-center" data-content="Serviço municipal"></div>

        <div class="columns">
          <div class="column col-3 form-group">
            <label for="service-code" class="form-label">Código</label>
            <input
              type="text"
              class="form-input"
              id="service-code"
              v-model="form.service.code"
              placeholder="Código"
            >
          </div>
          <div class="column col-9 form-group">
            <label for="service-description" class="form-label">Descrição</label>
            <input
              type="text"
              class="form-input"
              id="service-description"
              v-model="form.service.description"
              placeholder="Descrição do serviço"
            >
          </div>
        </div>
      </st-tab>

      <st-tab name="Dados de acesso" v-if="form.mappingId">
        <div class="upload-area mb-2" :class="{ dragging: certificate.dragging }">
          <input
            type="file"
            accept=".pfx,.p12"
            class="input-file"
            @dragenter="certificate.dragging = true"
            @dragleave="certificate.dragging = false"
            @drop="certificate.dragging = false"
            @change="certificateUploadChange"
          >
          <div class="upload-inner">
            <fa-icon :icon="['fal', 'upload']"></fa-icon>
            <p>
              Arraste seu certificado digital aqui
              ou clique para selecionar<br>
              <small>(Extensão PFX ou P12)</small>
            </p>
          </div>
        </div>

        <div class="form-group">
          <label for="certificate-name" class="form-label">Certificado digital</label>
          <input
            class="form-input"
            :value="certificate.file ? certificate.file.name : '--'"
            :readonly="true"
          >
        </div>

        <div
          class="form-group"
          :class="{ 'has-error': $v.certificate.password.$error }"
        >
          <label for="certificate-password" class="form-label">Senha do certificado</label>
          <st-input-password
            id="certificate-password"
            v-model="certificate.password"
            placeholder="******"
          />
          <template v-if="$v.certificate.password.$error">
            <div
              class="form-input-hint"
              v-if="!$v.certificate.password.required"
            >Campo obrigatório</div>
          </template>
        </div>

        <div class="text-center p-2">
          <button
            type="button"
            class="btn btn-primary"
            :class="{ loading: certificate.uploading }"
            @click="uploadCertificate"
            :disabled="!canUploadCertificate || certificate.uploading"
          >Enviar certificado</button>
        </div>
      </st-tab>
    </st-tabs>

    <template v-slot:footer>
      <div class="columns text-left">
        <div class="column">
          <button
            v-if="!form.mappingId"
            type="button"
            class="btn btn-gray-outline"
            :class="{ loading: fillingOut }"
            @click="fillForm"
          >Usar dados do cadastro</button>
        </div>
        <div class="column text-right">
          <button
            type="button"
            class="btn btn-primary"
            :class="{ loading: saving }"
            :disabled="saving"
            @click="save"
          >Salvar</button>
          <button
            type="button"
            class="btn ml-2"
            @click="close"
          >Sair</button>
        </div>
      </div>
    </template>
  </dx-modal>
</template>

<script>
import { mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import { cnpj, cep } from '@/data/validators';
import states from '@/data/states';
import { taxRegimes } from '@/data/invoice-tax-regimes';
import { specialTaxRegimes } from '@/data/invoice-special-tax-regimes';
import { legalNatures } from '@/data/invoice-legal-natures';
import { deepClone, mergeFrom } from '@/helpers/object';
import { clearNonNumbers } from '@/helpers/string';

export default {
  data() {
    return {
      loading: false,
      saving: false,
      fillingOut: false,
      form: this.blankForm(),
      certificate: {
        file: null,
        password: '',
        dragging: false,
        uploading: false,
      },
      states,
      taxRegimes,
      specialTaxRegimes,
      legalNatures,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        federalTaxNumber: { required, cnpj },
        email: { required, email },
        taxRegime: { required },
        legalNature: { required },
        municipalTaxNumber: { required },
        address: {
          postalCode: { required, cep },
          street: { required },
          number: { required },
          city: {
            name: { required },
            code: { required },
          },
          state: { required },
        },
      },
      certificate: {
        password: { required },
      },
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    branch() {
      return this.user.branch;
    },
    canUploadCertificate() {
      return !this.$v.certificate.$error && this.certificate.file;
    },
  },
  watch: {
    'form.address.city.name': function addressCityName() {
      const city = this.form.address.city.name ? this.form.address.city.name.trim() : '';
      if (city.toUpperCase() === 'CURITIBA') {
        this.form.address.city.code = '4106902';
      }
    },
  },
  methods: {
    async load() {
      try {
        const { data } = await this.$http.get(`/branches/${this.branch.id}/fiscal-setting`);
        if (data) {
          this.form = mergeFrom(this.blankForm(), data);
        }
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.$toast.error('Existem erros de preenchimento');
        return;
      }

      this.saving = true;
      try {
        const postData = deepClone(this.form);
        postData.federalTaxNumber = clearNonNumbers(postData.federalTaxNumber);
        postData.address.postalCode = clearNonNumbers(postData.address.postalCode);
        const { data } = await this.$http
          .post(`/branches/${this.branch.id}/fiscal-setting`, postData);
        this.form.mappingId = data.mappingId;
      } catch (e) {
        this.$toast.error(e);
      }
      this.saving = false;
    },
    certificateUploadChange(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        ([this.certificate.file] = e.target.files);
      } else {
        this.certificate.file = null;
      }
    },
    async uploadCertificate() {
      this.$v.certificate.$touch();
      if (this.$v.certificate.$error) {
        return;
      }

      this.certificate.uploading = true;
      try {
        const formData = new FormData();
        formData.append('file', this.certificate.file);
        formData.append('password', this.certificate.password);
        await this.$http
          .post(`/branches/${this.branch.id}/fiscal-setting/certificate`, formData);
        this.$toast.success('Certificado enviado com sucesso');
      } catch (e) {
        this.$toast.error(e);
      }
      this.certificate.uploading = false;
    },
    close() {
      this.$emit('close');
    },
    async fillForm() {
      this.fillingOut = true;
      try {
        const { data } = await this.$http.get(`/branches/${this.branch.id}?user=false`);
        this.form.name = data.companyName;
        this.form.federalTaxNumber = data.identity.value;
        this.form.tradeName = data.name;
        this.form.email = data.email;

        const [address] = data.addresses;
        if (address) {
          const [street, number] = address.addressLine1.split(',');
          this.form.address.postalCode = address.postalCode;
          this.form.address.street = street ? street.trim() : '';
          this.form.address.number = number ? number.trim() : '';
          this.form.address.additionalInformation = address.addressLine2;
          this.form.address.district = address.neighborhood;
          this.form.address.city.name = address.city;
          this.form.address.state = address.state;
        }
      } catch (e) {
        this.$toast.error(e);
      }
      this.fillingOut = false;
    },
    blankForm() {
      return {
        mappingId: null,
        name: '',
        federalTaxNumber: '',
        tradeName: '',
        email: '',
        taxRegime: '',
        specialTaxRegime: 'automatico',
        legalNature: '',
        regionalTaxNumber: '',
        municipalTaxNumber: '',
        issRate: 0,
        service: {
          code: '',
          description: '',
        },
        address: {
          country: 'BRA',
          postalCode: '',
          street: '',
          number: '',
          additionalInformation: '',
          district: '',
          city: {
            code: '',
            name: '',
          },
          state: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

#integration-invoice-modal {
  .upload-area {
    align-items: center;
    background-color: lighten($gray-color-light, 5%);
    border: lighten($gray-color, 5%) $unit-h dashed;
    display: flex;
    height: 7rem;
    justify-content: center;
    margin-top: $layout-spacing;
    position: relative;
    &.dragging {
      border-color: $primary-color;
    }
    .input-file {
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .upload-inner {
      display: flex;
      align-items: center;
    }
    svg {
      font-size: 1.8rem;
    }
    p {
      margin: 0 0 0 $layout-spacing;
      text-align: center;
    }
  }
}
</style>
